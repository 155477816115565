/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@use '~@angular/material' as mat;

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

$font-family: "Poppins, Lato, Helvetica, Arial";

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config($font-family: $font-family,
    $headline: mat.define-typography-level(32px, 48px, 700),
    $body-1: mat.define-typography-level(16px, 24px, 500));

@include mat.core($custom-typography);

.weekview-normal-event-container {
  margin-top: 108px !important;
}

ion-popover {
  &.forgot-password-popover {
    --max-width: 300px;
    --width: 300px;
  }
}

@media (max-width: 750px) {

  .weekview-allday-label,
  .calendar-hour-column {
    width: 50px !important;
  }
}

.loading-divinea {
  .loading-wrapper {
    display: block;
    text-align: center;
    box-shadow: none;

    ion-spinner {
      border-radius: 100px;
      width: 60px;
      height: 60px;
      background-image: url(/assets/img/loader.gif);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }

    .loading-content {
      margin-top: 10px;
      background-color: transparent;
      color: white;
      margin-left: unset;
      -webkit-margin-start: 0px !important;
      margin-inline-start: 0px !important;
    }

    background-color: transparent;
  }

  // Workaround para esconder o spinner nativo
  .spinner-dots {
    &.sc-ion-loading-md {
      color: transparent;
    }

    &.sc-ion-loading-ios {
      color: transparent;
    }
  }
}

.ion-page {
  background: white;
}

[hidden-but-there] {
  opacity: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  ;
  border: 0 !important;
}

[searchbar] {
  --background: rgba(var(--ion-color-primary-contrast, 0, 0, 0), 0.07);
  padding: 12px 25px 0 25px;

  .searchbar-input-container {
    border: 1px solid var(--ion-color-light-shade);
    border-radius: 20px;
    height: 30px;

    input {
      font-size: 14px;
    }
  }
}

[product-card] {
  text-align: center;
  width: 90%;
  border-radius: 25px;
  margin: 10px;
}

[app-header] {
  ion-title {
    padding-top: 5px;

    img {
      width: 90px;
    }
  }
}

[button-red] {
  color: #323337;
  --background: #F3F5F8;
  --background-activated: #F3F5F8;
  font-size: 15px;
  font-weight: bold;

  ion-icon {
    font-size: 25px;
    padding-left: 15px;
  }
}

[button-white] {
  color: #35373D;
  --background: #F3F5F8;
  --background-activated: #F3F5F8;
  font-size: 15px;
  font-weight: bold;

  ion-icon {
    font-size: 25px;
    padding-left: 15px;
  }
}

[item-input] {

  .label-stacked {
    font-size: 16px;
    --color: var(--txt-dark);
    margin-bottom: 5px;
  }

  ion-input {
    width: calc(100% - 10px);
    border: 1px solid var(--light-gray);
    border-radius: 8px;
    font-size: 14px;
    --padding-start: 15px !important;
    --color: var(--ion-color-medium);

    .native-input {
      height: 40px;
    }
  }
}

[empty-state] {
  margin-top: 40px;
  font-size: 18px;
  color: gray;
  text-align: center;
}

[footer-button] {
  padding: 10px 15px;
  background-color: var(--bg);

  ion-icon {
    padding-left: 23px;
  }
}

.remove-confirm {
  .alert-wrapper {
    --min-height: 200px;
    --min-width: 310px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .alert-title {
      font-size: 20px;
    }

    .alert-message {
      font-size: 16px;
    }

    .alert-button-group {
      height: 55px;

      .alert-button {
        height: 100%;
        font-size: 18px;

        &:last-of-type {
          color: var(--ion-color-primary);
        }
      }
    }
  }
}

ion-alert {
  .alert-wrapper.sc-ion-alert-md {
    max-width: 80%;
    width: 80%;

    .alert-radio-group.sc-ion-alert-md {
      .alert-radio-label.sc-ion-alert-md {
        text-overflow: clip;
        font-size: 0.8rem;
        text-wrap: wrap;
      }
    }

    @media (min-width: 500px) {
      max-width: 500px;
      width: 500px;
    }
  }
}